<template>
  <div class="marketing p-4">
    <OrgHeaderInfo
      :heading="$t('label.marketing').toString()"
      :sub-heading="marketing.description"
    />
    <Categories :categories="categories" :business-id="businessId" />
  </div>
</template>

<script>
import axios from "axios";
import Categories from "@/components/marketing/Categories.vue";
import { OrgHeaderInfo } from "@/v2/new-design-system";
import { propOr } from "ramda";

export default {
  name: "Marketing",
  components: {
    OrgHeaderInfo,
    Categories,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    marketing: {},
  }),
  computed: {
    categories() {
      return propOr([], "categories", this.marketing);
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        const response = await axios.get(process.env.VUE_APP_MARKETING_SOURCE);

        this.marketing = response.data.marketing;
      } catch (e) {
        //
      }
    },
  },
};
</script>
