<template>
  <LoadingZone :loading="loading" class="clearfix w-100 h-100">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="pl-4 m-0 bold truncate__lines--2">{{ title }}</h4>
      <a
        href="#"
        class="p-4"
        data-test-btnClose
        @click.prevent="$modal.hide('marketing_content')"
      >
        <TimesSVG class="w-10" />
      </a>
    </div>
    <iframe :src="link"></iframe>
  </LoadingZone>
</template>

<script>
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import TimesSVG from "@/assets/svg/icons/times.svg";

export default {
  name: "Modal",
  components: {
    LoadingZone,
    TimesSVG,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: true,
  }),
  created() {
    window.addEventListener("message", (event) => {
      if (event.data.type === "form-ready") {
        this.formIsReady();
      }
    });
  },
  methods: {
    formIsReady() {
      this.loading = false;
    },
  },
};
</script>
