<template>
  <div class="bg-light rounded p-30 pb-3 mb-5">
    <div class="row">
      <div class="col-lg">
        <h5 v-if="item.name" class="d-flex flex-wrap align-items-center">
          <strong class="d-inline-block mr-2 mb-2">{{ item.name }}</strong>
          <label
            v-if="item.tag"
            class="badge f-size-9 text-uppercase p-6 px-2"
            :class="
              item.tag == 'recommended' ? 'badge-success' : 'badge-warning'
            "
          >
            {{ item.tag }}
          </label>
        </h5>
        <p v-if="item.description" class="text-muted">
          {{ item.description }}
        </p>
      </div>
      <div v-if="item.link_url" class="offset-lg-1 col-lg-auto">
        <a
          href="#"
          class="btn btn-primary mb-3"
          data-test-openModal
          @click.prevent="linkHandler"
        >
          {{ item.link_text }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Content from "./Content.vue";
import { BUTTON_CLICKED } from "@/plugins/analytics/events";
import { getActiveBusiness } from "@/utils/mixins";

export default {
  name: "Item",
  mixins: [getActiveBusiness],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    linkHandler() {
      this.$analytics.trackEvent(BUTTON_CLICKED, {
        label: this.item.name,
      });

      if (this.item.go_to_vue_route) {
        this.$router.push({
          name: this.item.go_to_vue_route,
          params: { businessId: this.activeBusiness.id },
        });

        return;
      }

      const linkTypeform = this.item.link_url
        .replace("${businessName}", this.activeBusiness.name)
        .replace("${businessId}", this.activeBusiness.id);

      this.$modal.show(
        Content,
        {
          title: this.item.name,
          link: linkTypeform,
        },
        {
          name: "marketing_content",
          width: "80%",
          height: "80%",
          adaptive: true,
        }
      );
    },
  },
};
</script>
