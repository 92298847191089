<template>
  <div class="row">
    <div class="col-auto pt-4 mb-5">
      <div class="marketing__category">
        <a
          v-for="(category, index) in categories"
          :key="index"
          href="#"
          class="marketing__category--link"
          :class="{ active: showCategory === index }"
          @click.prevent="handleClick(index)"
        >
          {{ category.name }}
        </a>
      </div>
    </div>
    <div class="offset-md-1 col-md" style="max-width: 770px">
      <div
        v-for="(category, index) in categories"
        :key="index"
        :category="category"
      >
        <div v-show="index === showCategory" class="pt-4 mb-5">
          <div class="mb-5">
            <h5 class="bold mb-2">{{ category.name }}</h5>
            <p class="text-muted">{{ category.description }}</p>
          </div>
          <Item v-for="(item, key) in category.items" :key="key" :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Item from "./Item.vue";

export default {
  name: "Categories",
  components: {
    Item,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    showCategory: 0,
  }),
  methods: {
    handleClick(index) {
      const category = this.categories[index];

      if (category.link) {
        this.$router.push({
          name: category.link,
          params: { businessId: this.businessId },
        });

        return;
      }

      this.showCategory = index;
    },
  },
};
</script>
